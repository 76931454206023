<!-- 挂载--Z40S fckernel1027 -->
<template>
  <div class="cpt-MMC_Gimbal_Z40" :style="containerStyle">
    <div v-interact class="hd">
      <div class="left ml8">
        <img src="~@/assets/images/mount/mount_head.png" />
        <div class="title">高清变焦相机Z40S</div>
      </div>
      <div class="close" @click="$emit('close')">关闭</div>
    </div>
    <div class="bd">
      <div class="form-wrap">
        <div class="form-item">
          <div class="label-box">控制速度</div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                class="speed"
                :style="
                  control_speed == index + 1
                    ? 'background: #FFFFFF;color: #333333;'
                    : ''
                "
                @click="control_speed = index + 1"
              >
                {{ index + 1 }}
              </div>
            </div>
            <!-- <el-select
              v-model="control_speed"
              size="mini"
              placeholder="请选择控制速度"
            >
              <el-option
                v-for="(item, index) in new Array(5).fill(1)"
                :key="index"
                :label="index + 1"
                :value="index + 1"
              ></el-option>
            </el-select>-->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">云台模式</div>
          <div class="input-box">
            <div class="mono">
              <div class="mono-long">
                <div
                  class="mono-left mono_box"
                  :class="{ active: holderModelDomV2 == 0 }"
                  @click="handle_change_ptz_mode(0)"
                />
                <div
                  class="mono-middle mono_box"
                  :class="{ active: holderModelDomV2 == 1 }"
                  @click="handle_change_ptz_mode(1)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: holderModelDomV2 == 2 }"
                  @click="handle_change_ptz_mode(2)"
                />
              </div>
              <div class="text">
                <div class="text-left">锁定</div>
                <div class="text-right">跟随</div>
                <div class="text-right">回中</div>
              </div>
            </div>
            <!-- <el-radio-group @change="handle_change_ptz_mode" v-model="ptz_mode">
              <el-radio
                size="mini"
                v-for="item in drd.ptz_mode"
                :key="item.id"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group> -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">视频指点</div>
          <div class="input-box">
            <el-radio-group v-model="dj_mode" @change="handle_change_dj_mode">
              <el-radio
                v-for="item in drd.dj_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
        <div class="form_item">
          <span class="form_item_title">跟踪模式</span>
          <div>
            <div class="mono-long">
              <div
                class="mono-left mono_box"
                :class="{ activeG: holderModelDom == 0 }"
                @click="tatrack_Model(0)"
              />
              <div
                class="mono-middle mono_box"
                :class="{ activeG: holderModelDom == 2 }"
                @click="tatrack_Model(2)"
              />
              <div
                class="mono-right mono_box"
                :class="{ activeG: holderModelDom == 6 }"
                @click="tatrack_Model(6)"
              />
            </div>
            <div class="text">
              <div class="text-left">移动</div>
              <div class="text-right">跟踪</div>
              <div class="text-right">AI</div>
            </div>
          </div>
        </div>
        <div class="form_item">
          <div class="form_item_title">
            点&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;击
          </div>
          <div class="form_item">
            <div>
              <div class="mono-short">
                <div
                  class="mono-left mono_box"
                  :class="{ active: activeBox3 == 0 }"
                  @click="hand(1, 0)"
                />
                <div
                  class="mono-right mono_box"
                  :class="{ active: activeBox3 == 2 }"
                  @click="hand(6, 2)"
                />
              </div>
              <div class="jcsb cf ziticolor">
                <div class="text-left">移动</div>
                <div class="text-rights">聚焦</div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            变&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10"
              style="cursor: pointer"
              @mousedown="handle_zoom(-1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>-->
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              disabled
              @change="stopChange"
              @input="onChangezoom"
            />
            <div
              class="ml10"
              style="cursor: pointer"
              @mousedown="handle_zoom(1)"
              @mouseup="stopChange"
              @mouseout="stopChange"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            聚&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;焦
          </div>
          <div class="input-box">
            <div
              class="mr10"
              style="cursor: pointer"
              @mousedown="jujiao(-1)"
              @mouseup="stopjujiao"
              @mouseout="stopjujiao"
            >
              <span class="iconfont icon-shanchu3" />
            </div>
            <!-- <el-button
              @click="handle_zoom(-1)"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>-->
            <el-slider
              v-model="value"
              :max="50"
              :min="0"
              :show-tooltip="false"
              style="width: 10px"
              disabled
              @change="stopjujiao"
              @input="onChangezoom"
            />
            <div
              class="ml10"
              style="cursor: pointer"
              @mousedown="jujiao(1)"
              @mouseup="stopjujiao"
              @mouseout="stopjujiao"
            >
              <span class="iconfont icon-tianjia1" />
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            俯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;仰
          </div>
          <div class="input-box">
            <div class="speedC">
              <div
                v-for="(item, index) in regList"
                :key="index"
                class="pitch"
                :style="
                  pitch == item.num ? 'background: #FFFFFF;color: #333333;' : ''
                "
                @click="handle_change_pitch(item.num)"
              >
                {{ item.val }}
              </div>
            </div>
            <!-- <el-input
              v-model.number="pitch"
              size="mini"
              placeholder="请输入俯仰"
            />
            <span class="iconfont icon-shanchu3" @click="pitch--"></span>
            <span
              class="iconfont icon-tianjia1 mr5 ml3"
              @click="pitch++"
            ></span>

            <div class="butto" @click="handle_change_pitch" size="mini" type="primary"
              >设置</div
            > -->
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">
            航&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;向
          </div>
          <div class="input-box">
            <el-input
              v-model.number="yaw"
              size="mini"
              placeholder="请输入航向"
            />
            <span class="iconfont icon-shanchu3" @click="yaw--" />
            <span class="iconfont icon-tianjia1 mr5 ml3" @click="yaw++" />
            <!-- <el-button
              @click="yaw--"
              size="mini"
              icon="el-icon-minus"
              circle
            ></el-button>
            <el-button
              @click="yaw++"
              size="mini"
              icon="el-icon-plus"
              circle
            ></el-button>-->
            <div
              class="butto"
              size="mini"
              type="primary"
              @click="handle_change_yaw"
            >
              设置
            </div>
          </div>
        </div>
        <div class="form-item">
          <div class="label-box">相机模式</div>
          <div class="input-box">
            <el-radio-group
              v-model="camera_mode"
              @change="handle_change_camera_mode"
            >
              <el-radio
                v-for="item in drd.camera_mode"
                :key="item.id"
                size="mini"
                :label="item.id"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </div>
        </div>
      </div>
      <div class="rocker-wrap">
        <div class="item-group">
          <div class="item-box" @click="handle_take_photo">
            <el-tooltip
              class="item"
              effect="dark"
              content="拍照"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-paizhao1"></span> -->
                <img src="~@/assets/images/mount/photo.png" alt="" />
              </div>
            </el-tooltip>
          </div>
          <div class="item-box" @click="handle_record">
            <el-tooltip
              class="item"
              effect="dark"
              content="录像"
              placement="top"
            >
              <div class="icon-box">
                <!-- <span class="iconfont icon-dandianshipin"></span> -->
                <img src="~@/assets/images/mount/record.png" alt="" />
              </div>
            </el-tooltip>
          </div>
        </div>
        <div ref="rocker" class="rocker">
          <div
            class="shangUp"
            @mousedown="fangxiang('up')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="xaiUp"
            @mousedown="fangxiang('down')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="zuoUp"
            @mousedown="fangxiang('right')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
          <div
            class="youUp"
            @mousedown="fangxiang('left')"
            @mouseup="stopfxFn"
            @mouseout="stopfxFn"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { cal_crc_table, MMC_Gimbal_Z40S } from "../utils";
export default {
  props: {
    containerStyle: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: Object,
      default: () => ({}),
    },
    device: {
      type: Object,
      default: () => ({}),
    },
    payload: {
      type: Array,
      default: () => [],
    },
    selected_mount: {
      type: Object,
      default: () => ({}),
    },
    moveType: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      holderModelDomV2: 1,
      radio: 1,
      value: 25,
      value2: 25,
      control_speed: 3, // 控制速度
      ptz_mode: 0,
      zoom: 1,
      juj: 1,
      pitch: 0,
      yaw: 0,
      camera_mode: 0,
      record: false,
      dj_mode: 0,
      regList: [
        {
          val: "复位",
          num: 0,
        },
        {
          val: "30",
          num: -30,
        },
        {
          val: "60",
          num: -60,
        },
        {
          val: "90",
          num: -90,
        },
      ],
      drd: {
        dj_mode: [
          { id: 0, label: "关闭" },
          { id: 1, label: "打开" },
        ],
        ptz_mode: [
          { id: 0, label: "锁定" },
          { id: 1, label: "跟随" },
          { id: 2, label: "回中" },
        ],
        camera_mode: [
          { id: 0, label: "录像" },
          { id: 2, label: "拍照" },
        ],
      },
      timer: null,
      holderModelDom: 0, // 跟踪模式
      activeBox3: 0,
      click_mode_status: 1, // 点击事件
      IAMODE: 0, // 点击事件
      Gtimer: null,
      keyFlag: false,
      // 录像
      screenRecordingStatus: true,
    };
  },
  watch: {
    "$store.state.uavApplications.healthData": {
      handler(newval) {
        let arrKey = [];
        for (let i in newval) {
          arrKey.push(i);
        }
        let flage = arrKey.some((val) => val == "NX");
        if (flage && newval["NX"].warningLevel == "NORMAL") {
          this.keyFlag = true;
        } else {
          this.keyFlag = false;
        }
      },
      deep: true,
    },
    payload: {
      handler(value) {
        this.resolve_payload(value);
      },
      deep: true,
    },
  },
  beforeDestroy() {
    /* this.$store.commit("device/SET_MOVE_DATA", {
      ...this.device,
      mountStatus: 0,
    }); */
  },
  mounted() {
    // 第一调用
    this.ia_mode(0);
    this.$nextTick(() => {
      this.init_rocker();
    });
    this.tatrack_Model(0);
  },
  methods: {
    // 保存相机数据 ，在在指点时使用
    handle_change_dj_mode(id) {
      /* this.$store.commit("device/SET_MOVE_DATA", {
        ...this.device,
        mountStatus: id,
        mountId: this.selected_mount.mountId,
        moveType: this.moveType,
        name: this.selected_mount.name
      }); */
    },
    tatrack_switch(status) {
      const buff = new Array(0xa5, 0x0a, 3, 0x00, 0x00);
      buff[3] = status;
      buff[4] = cal_crc_table(buff);
      window.tatrack_AI_status = status;
      this.commit_directive(buff);
    },
    // 跟踪模式
    tatrack_Model(status) {
      if (status == 0) {
        window.click_mode_status = 1;
        this.tatrack_switch(status);
      } else if (status == 2) {
        this.tatrack_switch(status);
        window.click_mode_status = 3;
        setTimeout(() => {
          this.tatrack_switch(5);
        }, 100);
      } else if (status == 6) {
        this.tatrack_switch(3);
        setTimeout(() => {
          this.tatrack_switch(status);
        }, 100);
      }
      this.holderModelDom = status;
    },
    // 点击功能
    hand(e, num) {
      if (num == 0) {
        this.activeBox3 = 0;
        this.click_mode_status = e;
      } else if (num == 2) {
        this.activeBox3 = 2;
        this.click_mode_status = e;
      }
      this.ia_mode(0); // 发送指令修改相机为自动模式
    },

    ia_mode(ia_mode) {
      var buff = new Array(0xa5, 0x16, 0x03, 0x00, 0x00);
      buff[3] = ia_mode;
      buff[4] = cal_crc_table(buff);
      // 调用
      this.commit_directive(buff);
      this.IAMODE = ia_mode;
    },
    onChangezoom(e) {
      if (this.value2 < this.value) {
        this.handle_zoom(1);
      } else if (this.value2 > this.value) {
        this.handle_zoom(-1);
      }
      this.value2 = e;
    },

    // 变焦停止
    stopChange() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      const buffer = MMC_Gimbal_Z40S.zoom((this.zoom = 1));
      this.commit_directive(buffer);
    },
    // 聚焦停止
    stopjujiao() {
      this.value = 25;
      this.value2 = 25;
      // this.handle_zoom(this)
      const buffer = MMC_Gimbal_Z40S.ju((this.juj = 1));
      this.commit_directive(buffer);
    },
    resolve_payload(buff) {
      const dataArray = new Uint8Array(buff);
      if (
        dataArray[0] === 0xa5 &&
        cal_crc_table(dataArray) === dataArray[dataArray.length - 1]
      ) {
        switch (dataArray[1]) {
          case 0x01:
            this.msg_key_status1(dataArray);
            break;
          case 0xf8:
            this.gcs_button_ctrl(dataArray);
            break;
        }
      }
    },
    gcs_button_ctrl(data) {
      const button = data[3];
      const status = data[4];

      switch (button) {
        case 4:
          this.gimbal_mode_ctrl(status);
          break;
        default:
          break;
      }
    },
    msg_key_status1(data) {
      this.gimbal_mode_ctrl(data[7] & 0x3);
    },
    gimbal_mode_ctrl(mode) {
      this.ptz_mode = mode;
    },

    // 云台模式
    handle_change_ptz_mode(value) {
      this.holderModelDomV2 = value;
      const buffer = MMC_Gimbal_Z40S.gimbal_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    // 变焦事件
    handle_zoom(value) {
      const buffer = MMC_Gimbal_Z40S.zoom(this.zoom + value);
      this.commit_directive(buffer);
      // buffer = MMC_Gimbal_Z40.zoom((this.zoom = 1));
      // this.commit_directive(buffer);
    },
    // 聚焦事件
    jujiao(value) {
      const buffer = MMC_Gimbal_Z40S.ju(this.juj + value);
      this.commit_directive(buffer);
    },
    handle_change_pitch(num) {
      this.pitch = num;
      const buffer = MMC_Gimbal_Z40S.change_pitch_angle(this.pitch);
      this.commit_directive(buffer);
    },
    handle_change_yaw() {
      const buffer = MMC_Gimbal_Z40S.change_yaw_angle(this.yaw);
      this.commit_directive(buffer);
    },
    // 相机模式
    handle_change_camera_mode(value) {
      const buffer = MMC_Gimbal_Z40S.camera_mode_ctrl(value);
      this.commit_directive(buffer);
    },
    // 拍照
    handle_take_photo() {
      const { position } = this;
      if (!this.keyFlag) return this.$message.error("录像失败，NX通信异常！");
      const buffer = MMC_Gimbal_Z40S.take_photo(position || {});
      this.commit_directive(buffer);
      this.$store.dispatch("uavApplications/TakePhoto", {
        callback: (isOk) => {
          isOk && console.log("拍照");
        },
      });
    },
    screenRecording() {
      this.$store.dispatch("uavApplications/videoTranscribe", {
        videoID: 1, // 视频通道ID（需保持唯一）
        dbID: "929", // 历史记录id
        taskID: "", // 任务ID，可传可不传
        recordControl: true, // 录制开关、true为开启
        status: this.screenRecordingStatus,
        callback: (isOk) => {
          isOk &&
            this.$message.success(
              `${this.screenRecordingStatus ? "开始" : "结束"}录屏`
            );
          this.screenRecordingStatus = !this.screenRecordingStatus;
        },
      });
    },
    // 录像
    handle_record() {
      if (!this.keyFlag) return this.$message.error("录像失败，NX通信异常！");
      this.record = !this.record;
      const buffer = MMC_Gimbal_Z40S.record(this.record);
      this.commit_directive(buffer);
      this.screenRecording();
    },
    commit_directive(buffer) {
      this.$emit("directive", buffer);
    },
    fangxiang(type) {
      let buffer = null;
      switch (type) {
        case "up":
          buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "left":
          buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "right":
          buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
          break;
        case "down":
          buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(-1, this.control_speed);
          this.commit_directive(buffer);
      }
      // this.timer = setTimeout(() => {
      //   clearTimeout(this.timer);
      //   buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      //   buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(0, this.control_speed);
      //   this.commit_directive(buffer);
      // }, 500);
    },
    stopfxFn() {
      let buffer = null;
      buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
      buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(0, this.control_speed);
      this.commit_directive(buffer);
    },
    init_rocker() {
      let buffer = null;
      joystick({
        // zone: this.$refs["rocker"],
        // mode: "static",
        // position: { left: "50%", top: "50%" },
        // color: "#9bacbe",
        onstart: (type) => {
          switch (type) {
            case "up":
              buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "right":
              buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "left":
              buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(-1, this.control_speed);
              this.commit_directive(buffer);
              break;
            case "down":
              buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(
                -1,
                this.control_speed
              );
              this.commit_directive(buffer);
          }
        },
        onend: () => {
          buffer = MMC_Gimbal_Z40S.gimbal_pitch_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
          buffer = MMC_Gimbal_Z40S.gimbal_yaw_ctrl(0, this.control_speed);
          this.commit_directive(buffer);
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-MMC_Gimbal_Z40 {
  position: absolute;
  right: 0;
  top: 0;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 10px;
  width: 500px;
  //   height: 290px;
  box-sizing: border-box;

  // padding: 10px 20px;
  .hd {
    height: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: rgba(16, 65, 215, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
    border-radius: 10px 10px 0 0;
    .left {
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .close {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      margin-right: 8px;
      cursor: pointer;
    }
  }

  .bd {
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    height: 234px;
    overflow-y: auto;

    .form-wrap {
      flex-shrink: 0;

      .form-item {
        display: flex;
        align-items: center;
        height: 40px;

        .label-box {
          color: #cbd0eb;
          width: 75px;
        }

        .input-box {
          display: flex;
          align-items: center;
          .mono {
            display: flex;
            flex-direction: column;
            .mono-long {
              display: flex;
              justify-content: space-between;
              width: 168px;
              height: 17px;
              background: url("~@/assets/images/mount_long.png") no-repeat;
              background-size: 100%;
              margin-bottom: 5px;
              .mono_box {
                width: 28px;
                height: 13px;
                border-radius: 3px;
                margin-top: 2px;
                cursor: pointer;
              }
              .active {
                background: url("~@/assets/images/mount/mount_control.png")
                  no-repeat !important;
                background-size: 100% 100%;
              }
            }
            .text {
              font-size: 14px;
              font-family: MicrosoftYaHei;
              color: #ffffff;
              display: flex;
              justify-content: space-between;
              font-size: 14px;
            }
          }
        }
      }
    }

    .rocker-wrap {
      flex: 1;
      margin: auto;

      .item-group {
        display: flex;
        justify-content: space-evenly;

        .item-box {
          .icon-box {
            width: 32px;
            height: 32px;
            background: #000;
            border-radius: 32px;
            display: flex;
            align-items: center;
            justify-content: center;

            .iconfont {
              font-size: 24px;
              color: #dce9ff;
              cursor: pointer;
            }
          }
        }
      }

      .rocker {
        margin: 10px auto 0;
        width: 150px;
        height: 150px;
        position: relative;
        margin: 0 auto;
        background: center url("~@/assets/images/accident/caozuo.png") no-repeat;
        background-size: 100% 100%;

        .shangUp {
          position: absolute;
          left: 37%;
          top: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .xaiUp {
          position: absolute;
          left: 37%;
          bottom: 4px;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .zuoUp {
          position: absolute;
          left: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }

        .youUp {
          position: absolute;
          right: 4px;
          top: 37%;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          border: 0px solid red;
          cursor: pointer;
        }
      }
    }
  }
}

::v-deep {
  .el-select {
    width: 165px;
    margin-right: 5px;

    .el-input {
      .el-input__inner {
        background: #000000;
        border: 1px solid #004fff;
        color: #dce9ff;
      }
    }
  }

  .el-input {
    width: 165px;
    margin-right: 5px;

    .el-input__inner {
      background: #000000;
      border: 1px solid #004fff;
      color: #dce9ff;
    }
  }

  .el-radio-group {
    .el-radio {
      .el-radio__input {
        &.is-checked {
          .el-radio__inner {
            border-color: #004fff;
            background: #004fff;
          }
        }
      }

      .el-radio__label {
        color: #cbd0eb;
      }
    }
  }
  .butto {
    width: 35px;
    height: 25px;
    font-size: 12px;
    line-height: 25px;
    text-align: center;
    background: #004fff;
    color: #000;
    border-radius: 5px;
  }
  .el-button {
    background: #2aefed;
    color: #000;
    border: none;
  }
}

::v-deep .el-slider__bar {
  background: #fff;
}

.speedC {
  width: 203px;
  height: 24px;
  display: flex;
  background: rgba(13, 34, 79, 0.65);
  border: 1px solid #004fff;
  border-radius: 12px;

  .speed {
    width: 41px;
    // background: #FFFFFF;
    color: #ffffff;
    border-radius: 12px;
    text-align: center;
    line-height: 24px;
  }
}

.iconfont {
  color: #004fff;
}

// 变焦功能
::v-deep .el-slider__runway {
  visibility: hidden;
}

.form_item {
  display: flex;
  // margin-bottom: 15px;
  // text-align: end;
  color: #cbd0eb;

  .form_item_title {
    width: 85px;
  }

  .mono_box {
    width: 28px;
    height: 13px;
    border-radius: 3px;
    margin-top: 2px;
    cursor: pointer;
  }

  .active {
    background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
    // background: url("~@/assets/images/accident/mono_box.svg") no-repeat !important;
    background-size: 100% 100%;
  }

  .mono-short,
  .mono-long,
  .slider,
  .text {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
  }

  .mono-left {
    margin-left: 2px;
  }

  .mono-right {
    margin-right: 2px;
  }

  .mono-short {
    width: 111px;
    height: 17px;
    background: url("~@/assets/images/mount_short.png") no-repeat;
    background-size: 100%;
    margin-bottom: 5px;
  }

  .mono-long {
    width: 168px;
    height: 17px;
    background: url("~@/assets/images/mount_long.png") no-repeat;
    background-size: 100%;
    margin-bottom: 5px;
  }

  .imgs {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;

    & > img {
      cursor: pointer;
      width: 32px;
      height: 32px;

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .slider_box {
    width: 140px;
    margin: -7px 5px 0;
  }

  .symbol {
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #004fff;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    padding-left: 1px;

    &:hover {
      opacity: 0.7;
    }
  }
}

.img_bottom {
  display: flex;
  width: 320px;
  justify-content: space-between;

  & > img {
    cursor: pointer;
    width: 130px;
    height: 130px;
  }
}

::v-deep {
  .el-select,
  .el-input {
    width: 84px;
    height: 24px;
  }

  .el-input__inner {
    width: 84px;
    height: 24px;
    background: rgba(13, 34, 79, 0.65);
    border: 1px solid #004fff;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
  }

  .el-select .el-input .el-select__caret {
    line-height: 26px;
  }

  // 修改input清除按钮样式
  .el-input__suffix {
    .el-input__suffix-inner {
      .el-icon-circle-close:before {
        line-height: 16px;
        position: absolute;
        top: 5px;
        right: 3px;
      }
    }
  }

  .el-button {
    margin-left: 10px;
    padding: 0;
    background: #2aefed;
    border-radius: 2px;
    width: 43px;
    height: 24px;
    outline: none;
    color: #000000;
    border: none;

    &:hover {
      opacity: 0.7;
    }
  }
}

.text-right {
  width: 30px;
}

.activeG {
  background: url("~@/assets/images/mount/mount_control.png") no-repeat !important;
  background-size: 100% 100%;
}

.ziticolor {
  color: #dce9ff;
  font-size: 14px;
}
</style>
